import React, { Component } from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom'
import Loading from './components/utils/Loading';
import MainApp from './components/main_screens/main_app';
import Login from './components/main_screens/login';
import AgeVerification from './components/main_screens/AgeVerification';
import RedeemedViewer from "./components/main_screens/RedeemedViewer";
import {base} from './base';
import ReactGA from 'react-ga';
import FallbackLocalStorage from "fallback-local-storage";
let appStorage;

if(process.env.NODE_ENV === "production"){
  ReactGA.initialize(process.env.REACT_APP_GA_KEY);
}

function logPageView() {
  if(process.env.NODE_ENV === "production"){
    ReactGA.set({ page: window.location.pathname });
    ReactGA.pageview(window.location.pathname)
  }
}

function PrivateRoute({ authenticated, children }) {
    return authenticated === true ? children : <Navigate to="/login" />;
}

class App extends Component {
    constructor(props) {
        super(props)
        this.setCurrentUser = this.setCurrentUser.bind(this)
        let userEmail;
        try {
            userEmail = appStorage.getItem('userEmail') || false;
        } catch (e) {
            userEmail = false
        }
        this.state = {
            authenticated: !!userEmail,
            variables: {},
            loading: true,
            passedEmail: false
        }
    }

    setCurrentUser() {
        const userEmail = appStorage.getItem('userEmail') || false;
        this.setState({
            authenticated: !!userEmail
        })
    }

    componentDidMount() {
        this.tenantVariablesRef = base.listenTo(`tenantVariables`, {
            context: this,
            state: 'tenantVariables',
            then(variables){
                document.title = variables.pageTitle || "Bingo";
                this.setState({
                    authenticated: false,
                    variables: variables,
                    loading: false,
                })
                this.setUpStorage(variables)
            }
        });
    }

    setUpStorage(variables){
        const keepFansLoggedIn = variables.keepFansLoggedIn || false;
        if (FallbackLocalStorage.getStorage().includes("sessionStorage") && !keepFansLoggedIn) {
            // Here we don't have any problems
            // with writing to `window.localStorage`
            appStorage = globalThis.sessionStorage;
        } else if(FallbackLocalStorage.getStorage().includes("localStorage") && keepFansLoggedIn) {
            appStorage = globalThis.localStorage;
        } else {
            // Looks like we have some troubles.
            // Browser has disable `window.localStorage` support.
            // Or browser is in `Private Mode`
            // which disables localStorage completely.
            appStorage = new FallbackLocalStorage();
        }
    }

    checkForAgeGate(tenantVariables){
        const variable_age_boolean = tenantVariables.collectBirthday || false;
        if(!variable_age_boolean){
            return true
        }
        const formBirthday = tenantVariables.formBirthday || false;
        let variable_age = tenantVariables.allowedAge || 21;
        variable_age = parseInt(variable_age, 10);
        let user_age = appStorage.getItem('verifiedAge') || false;
        if(isNaN(parseInt(user_age))){
            user_age = false
        } else {
            user_age = parseInt(user_age, 10)
        }
        return (user_age && user_age >= variable_age) || formBirthday;
        }

    setPassedEmail(isSet=true){
        this.setState({
          passedEmail: isSet
        })
    }

    componentWillUnmount() {
        base.removeBinding(this.tenantVariablesRef);
    }

    render() {
        if (this.state.loading === true) {
            return (
                <Loading loading={this.state.loading}/>
            )
        }
        return (
            <BrowserRouter>
                <Routes>
                    <Route
                        path="/login"
                        element={<Login appStorage={appStorage} authenticated={this.state.authenticated} loadPage={() => logPageView()} setCurrentUser={this.setCurrentUser} variables={this.state.variables} setPassed={(setPassed)=>this.setPassedEmail(setPassed)} checkForAgeGate={this.checkForAgeGate} />}
                    />
                    <Route
                        path="/"
                        element={
                            <PrivateRoute authenticated={this.state.authenticated}>
                                <MainApp appStorage={appStorage} variables={this.state.variables} passedEmail={this.state.passedEmail} loadPage={() => logPageView()} checkForAgeGate={this.checkForAgeGate} />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/age_gate"
                        element={<AgeVerification appStorage={appStorage} loadPage={() => logPageView()} variables={this.state.variables} checkForAgeGate={this.checkForAgeGate} />}
                    />
                    <Route
                        path="/redeemviewer"
                        element={<RedeemedViewer appStorage={appStorage} loadPage={() => logPageView()} tenantVariables={this.state.variables} />}
                    />
                    <Route
                        path="*"
                        element={<Navigate to="/login" replace />}
                    />
                </Routes>
            </BrowserRouter>
        )
    }
}

export default App;
